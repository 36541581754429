.App {
    text-align: center;
}

.culpa-blue {
    background-color: #1d2c4d !important;
}

.culpa-blue-text {
    color: #1d2c4d !important;
}

.black-text {
    color: #000000 !important;
}

.white-text {
    color: #ffffff !important;
}

.culpa-orange {
    background-color: #fd9332 !important;
}

.orange-button {
    background-color: #fd9332 !important;
    color: #1d2c4d !important;
    font-family: Consolas, monospace !important;
    font-size: 1.2rem !important;
}

.width-25 {
    width: 25%;
}

.width-35 {
    width: 35%;
}

.width-50 {
    width: 50%;
}

.width-70 {
    width: 70%;
}

.width-100 {
    width: 100%;
}

.width-300px {
    width: 300px;
}

.no-margin-bottom {
    margin-bottom: 0px !important;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.rounded {
    border-radius: 20px !important;
}

.menu-column {
    max-width: 350px;
}

.file-input-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
}

.color {
    color: #ffedb8;
}

.cursor-pointer {
    cursor: pointer;
}

.search-barr {
    border-radius: 0.5em !important;
}

.search-barr .ui.input > input {
    border-radius: 0.5em !important;
    font-size: 1.2em !important;
}

.font-size-20 {
    font-size: 50px;
}

.consolas-font {
    color: white;
    font-size: 52px;
    line-height: 68px;
    font-weight: 700;
    width: 80%;
    min-height: 200px;
    font-family: 'Source Code Pro', monospace !important; /* Fixed line */
}

@media (max-width: 768px) {
    .consolas-font {
        font-size: 40px;
        line-height: 48px;
    }
}

@media (max-width: 540px) {
    .consolas-font {
        font-size: 34px;
        line-height: 38px;
    }
}

.front-page-numbers {
    color: white;
    font-size: 80px;
    font-weight: 700;
    line-height: 1; /* Important to control line height for numbers */
}

.front-page-labels {
    color: #d6e9fa;
    font-size: 42px;
    font-weight: 700;
    line-height: 57px;
}

@media (max-width: 768px) {
    .front-page-numbers {
        font-size: 54px;
        line-height: 76px;
    }

    .front-page-labels {
        font-size: 28px;
        line-height: 40px;
    }
}

@media (max-width: 540px) {
    .front-page-numbers {
        font-size: 42px;
        line-height: 60px;
    }

    .front-page-labels {
        font-size: 22px;
        line-height: 32px;
    }
}

.footer-text {
    font-family: 'Source Code Pro', monospace !important; /* Fixed line */
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
}

.nugget-explanation-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #2a3857;
    background-color: #f4f6f8;
}

.pending-reviews-container {
    position: relative;
}

.pending-reviews-container .ui.card {
    margin-bottom: 1em;
}

/* Add smooth transition for card height/margin changes */
.pending-reviews-container .ui.card {
    transition:
        height 5s ease-out,
        margin 5s ease-out,
        padding 5s ease-out,
        opacity 5s ease-out,
        transform 5s ease-out;
}

/* HomePage.css */

.homepage-container {
    min-height: 100vh;
    background-color: #1d2c4d;
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.modern-culpa-container {
    padding-bottom: 10em;
}

.culpa-icon-text-container {
    padding-bottom: 1em;
}

.culpa-icon {
    padding-bottom: 5em;
}

.homepage-title {
    color: #d6e9fa;
    font-size: 48px;
    line-height: 68px;
    font-weight: bold;
    width: 80%;
    white-space: pre-wrap;
    min-height: 200px;
}

.search-bar-container {
    padding-bottom: 2em;
}

.nugget-explanation-section {
    width: 100%;
    height: 100%;
    background-color: #f4f6f8;
}

.nugget-explanation-container {
    display: flex;
    justify-content: center;
    padding: 1em;
    align-items: center;
}

.nugget-explanation-text {
    padding-right: 2em;
}

.nugget-icon {
    width: 40px;
    margin-right: 1em;
}

.recent-reviews-header-container {
    margin-top: 2em; /* Added for visual spacing, adjust as needed */
    margin-bottom: 2em; /* Added for visual spacing, adjust as needed */
}

.stats-container {
    background-color: #1d2c4d;
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5%;
    padding-bottom: 15%;
}

.typewriter-container {
    padding-bottom: 0em;
}

.typewriter-text {
    min-height: 100px; /* Ensure the text area has height even with shorter strings */
}

.stats-grid {
    margin: 0; /* Reset default grid margins if any */
}

.stats-row {
    align-items: center !important;
    display: flex;
}

.stats-numbers-column {
    justify-content: flex-end !important;
    display: flex !important;
}

.stats-number {
    padding-right: 0.5em;
}

/* You should ensure these classes are defined either in a global stylesheet or in this CSS file if they are specific to this component.
   If they are meant to be global, consider moving them to a file like `index.css` or `global.css` and import that in your root component.
   For now, I'm including them here based on the original code context. */

.orange-button {
    /* Example styles - replace with your actual orange-button styles */
    background-color: #ffa500; /* Example orange color */
    color: white;
    font-weight: bold;
    /* Add other styles as needed */
}

.nugget-explanation-text {
    /* If there were specific font styles, add them here. If it's just default, you might not need to redefine. */
}

.consolas-font {
    font-family:
        'Consolas', monospace; /* Example - ensure Consolas or a fallback is available */
    color: white; /* As seen in the context */
}

.no-margin-left-right {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.quote-text {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #2a3857;
    font-family:
        Source Code Pro,
        monospace;
}

.quote-footer {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: #2a3857;
}

.quote-left {
    padding-right: 2em;
}

.quote-right {
    padding-left: 2em;
}

.quote-container.quote-text {
    display: flex;
    align-items: flex-start;
}

.quote-icon {
    align-self: flex-start; /* Ensures icons stay top-aligned even if container height increases */
}

.field-error {
    margin-top: 0em !important;
    margin-bottom: 1em !important;
}

.ui.stackable.divided.grid.margin-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.ui.fluid.card.w-100 {
    width: 100% !important;
    padding-right: 0em !important;
    padding-left: 0em !important;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
}

.load-more-button {
    background-color: #cce2ff !important;
}

a {
    color: #4071ca;
}

.ui.card.fluid.review-card-padding {
    padding-left: 1em !important;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
}

.culpa-lighter-blue {
    background-color: #2d4f8a !important;
}

@media only screen and (max-width: 767px) {
    .ui.stackable.grid .ui.stackable.grid.column.padding-card,
    .ui.stackable.grid > .row > .column.padding-card,
    .ui.stackable.grid > .column.padding-card,
    .ui.grid .ui.stackable.grid .column.padding-card,
    .ui.grid > .row > .column.padding-card.mobile.sixteen.wide,
    html body .ui.grid .column.padding-card[class*='mobile sixteen wide'] {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

@media only screen and (max-width: 767px) {
    .ui.stackable.grid .ui.stackable.grid.column.padding-footer,
    .ui.stackable.grid > .row > .column.padding-footer,
    .ui.stackable.grid > .column.padding-footer,
    .ui.grid .ui.stackable.grid .column.padding-footer,
    .ui.grid > .row > .column.padding-footer.mobile.sixteen.wide,
    html body .ui.grid .column.padding-footer[class*='mobile sixteen wide'],
    .ui .container {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
}

@media only screen and (min-width: 768px) {
    .ui.stackable.grid .ui.stackable.grid.column.padding-card,
    .ui.stackable.grid > .row > .column.padding-card,
    .ui.stackable.grid > .column.padding-card,
    .ui.grid .ui.stackable.grid .column.padding-card,
    .ui.grid > .row > .column.padding-card,
    html body .ui.grid .column.padding-card {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}
